exports.onServiceWorkerUpdateFound = () => {
  var event = new Event('serviceWorkerUpdateFound')
  window.dispatchEvent(event)
}

exports.onServiceWorkerActive = () => {
  var event = new Event('serviceWorkerActive')
  window.dispatchEvent(event)
}

exports.onInitialClientRender = () => {
  // Workaround for IE11 - for now I just do it like this as I think IE will be gone
  // by the time the workaround becomes an issue
  if (typeof(Event) === 'function') {
    var event = new Event('initialClientRender')
    window.dispatchEvent(event)
  } else if (typeof(window.store) === 'object') {
    window.store.dispatch({type: 'REFERESH'})
  }
}

exports.shouldUpdateScroll = ({
  routerProps: { location },
}) => {
  if (location.pathname.startsWith('/movie'))  {
    return [0,0]
  }
  return true
}