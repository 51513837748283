module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N53FZSQ","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cinema Odeon 6","short_name":"Cinema Odeon 6","start_url":"/","background_color":"#FFDC00","theme_color":"#FFDC00","display":"fullscreen","icon":"src/images/odeon6-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"37be6cf054a2b66d10e1d857d9c60113"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
